import Nanci from '../../assets/Nanci.jpg'
import Crawford from '../../assets/Crawford.jpg'
import Lindsey from '../../assets/Lindsey.jpg'
import Hunter from '../../assets/Hunter.jpg'

const teamData = [
    {
        name: 'Nanci Sexton',
        photo: Nanci,
        title: 'vice president',
    },
    {
        name: 'Crawford Miller',
        photo: Crawford,
        title: 'VP Managing Director',
    },
    {
        name: 'Lindsey Vansant',
        photo: Lindsey,
        title: 'business manager',
    },
    {
        name: 'Hunter Martin',
        photo: Hunter,
        title: 'president'
    }
];

export default teamData;
